var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "personalDetail" },
    [
      _c("app-header", { attrs: { title: _vm.title, isShowBack: true } }),
      _c("div", { staticClass: "info" }, [
        _c("div", { staticClass: "base-info" }, [
          _c("div", { staticClass: "info-title" }, [_vm._v("基本信息")]),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "row-title" }, [_vm._v("注册主体类型")]),
              _c("el-input", {
                staticClass: "row-value",
                attrs: { disabled: "" },
                model: {
                  value: _vm.typeStr,
                  callback: function($$v) {
                    _vm.typeStr = $$v
                  },
                  expression: "typeStr"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "row-title" }, [
                _vm._v(_vm._s(_vm.type == 1 ? "姓名" : "企业名称"))
              ]),
              _c("el-input", {
                staticClass: "row-value",
                attrs: { disabled: "" },
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = $$v
                  },
                  expression: "name"
                }
              })
            ],
            1
          ),
          _vm.type == 2
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("div", { staticClass: "row-title" }, [
                      _vm._v("统一信用代码")
                    ]),
                    _c("el-input", {
                      staticClass: "row-value",
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.creditCode,
                        callback: function($$v) {
                          _vm.creditCode = $$v
                        },
                        expression: "creditCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("div", { staticClass: "row-title" }, [
                      _vm._v("企业法人姓名")
                    ]),
                    _c("el-input", {
                      staticClass: "row-value",
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.legalName,
                        callback: function($$v) {
                          _vm.legalName = $$v
                        },
                        expression: "legalName"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "row-title" }, [
                _vm._v(_vm._s(_vm.type == 1 ? "性别" : "法人性别"))
              ]),
              _c("el-input", {
                staticClass: "row-value",
                attrs: { disabled: "" },
                model: {
                  value: _vm.sexStr,
                  callback: function($$v) {
                    _vm.sexStr = $$v
                  },
                  expression: "sexStr"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "row-title" }, [
                _vm._v(_vm._s(_vm.type == 1 ? "证件类型" : "法人证件类型"))
              ]),
              _c("el-input", {
                staticClass: "row-value",
                attrs: { disabled: "" },
                model: {
                  value: _vm.cardTypeStr,
                  callback: function($$v) {
                    _vm.cardTypeStr = $$v
                  },
                  expression: "cardTypeStr"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "row-title" }, [
                _vm._v(_vm._s(_vm.type == 1 ? "证件号码" : "法人证件号码"))
              ]),
              _c("el-input", {
                staticClass: "row-value",
                attrs: { disabled: "" },
                model: {
                  value: _vm.cardNoStr,
                  callback: function($$v) {
                    _vm.cardNoStr = $$v
                  },
                  expression: "cardNoStr"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "row-title" }, [_vm._v("证件有效期")]),
              _c("el-input", {
                staticClass: "row-value",
                attrs: { disabled: "" },
                model: {
                  value: _vm.validate,
                  callback: function($$v) {
                    _vm.validate = $$v
                  },
                  expression: "validate"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row address" },
            [
              _c("div", { staticClass: "row-title" }, [_vm._v("联系地址")]),
              _c("el-input", {
                staticClass: "row-value",
                attrs: {
                  type: "textarea",
                  autosize: { maxRows: 6 },
                  disabled: ""
                },
                model: {
                  value: _vm.address,
                  callback: function($$v) {
                    _vm.address = $$v
                  },
                  expression: "address"
                }
              })
            ],
            1
          ),
          _vm.type == 2
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "row", staticStyle: { display: "flex" } },
                  [
                    _c("div", { staticClass: "row-title" }, [
                      _vm._v("法人身份证国徽面")
                    ]),
                    _vm.identityReverseUrl
                      ? _c("img", {
                          staticStyle: { width: "120px", height: "120px" },
                          attrs: { src: _vm.identityReverseUrl }
                        })
                      : _vm._e()
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "row", staticStyle: { display: "flex" } },
                  [
                    _c("div", { staticClass: "row-title" }, [
                      _vm._v("法人身份证人像面")
                    ]),
                    _vm.identityFrontUrl
                      ? _c("img", {
                          staticStyle: { width: "120px", height: "120px" },
                          attrs: { src: _vm.identityFrontUrl }
                        })
                      : _vm._e()
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "row", staticStyle: { display: "flex" } },
                  [
                    _c("div", { staticClass: "row-title" }, [
                      _vm._v("营业执照")
                    ]),
                    _vm.certificateUrl
                      ? _c("img", {
                          staticStyle: { width: "120px", height: "120px" },
                          attrs: { src: _vm.certificateUrl }
                        })
                      : _vm._e()
                  ]
                )
              ])
            : _vm._e()
        ]),
        _vm.bindState
          ? _c("div", { staticClass: "base-info" }, [
              _c("div", { staticClass: "info-title" }, [
                _vm._v(
                  _vm._s(
                    _vm.type == 1 ? "银行账户信息" : "银行账户信息（对公账户）"
                  )
                )
              ]),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("div", { staticClass: "row-title" }, [
                    _vm._v("开户行名称")
                  ]),
                  _c("el-input", {
                    staticClass: "row-value",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.openBank,
                      callback: function($$v) {
                        _vm.openBank = $$v
                      },
                      expression: "openBank"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row address" },
                [
                  _c("div", { staticClass: "row-title" }, [_vm._v("支行行名")]),
                  _c("el-input", {
                    staticClass: "row-value",
                    attrs: {
                      type: "textarea",
                      autosize: { maxRows: 6 },
                      disabled: ""
                    },
                    model: {
                      value: _vm.branchBank,
                      callback: function($$v) {
                        _vm.branchBank = $$v
                      },
                      expression: "branchBank"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("div", { staticClass: "row-title" }, [_vm._v("支行行号")]),
                  _c("el-input", {
                    staticClass: "row-value",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.branchBankNo,
                      callback: function($$v) {
                        _vm.branchBankNo = $$v
                      },
                      expression: "branchBankNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("div", { staticClass: "row-title" }, [_vm._v("账户名称")]),
                  _c("el-input", {
                    staticClass: "row-value",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.accountName,
                      callback: function($$v) {
                        _vm.accountName = $$v
                      },
                      expression: "accountName"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("div", { staticClass: "row-title" }, [_vm._v("账号")]),
                  _c("el-input", {
                    staticClass: "row-value",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.bankNo,
                      callback: function($$v) {
                        _vm.bankNo = $$v
                      },
                      expression: "bankNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("div", { staticClass: "row-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.type == 1
                          ? "银行预留手机号码"
                          : "对公账户法人手机号码"
                      )
                    )
                  ]),
                  _c("el-input", {
                    staticClass: "row-value",
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.bankPhone,
                      callback: function($$v) {
                        _vm.bankPhone = $$v
                      },
                      expression: "bankPhone"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _c("div", { staticClass: "base-info" }, [
          _c("div", { staticClass: "info-title" }, [
            _vm._v("手机号码（用于经销商登录）")
          ]),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "row-title" }, [
                _vm._v("手机号码（登录账号）")
              ]),
              _c("el-input", {
                staticClass: "row-value",
                attrs: { disabled: "" },
                model: {
                  value: _vm.mobile,
                  callback: function($$v) {
                    _vm.mobile = $$v
                  },
                  expression: "mobile"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "row-title" }, [_vm._v("登录密码")]),
              _c("el-input", {
                staticClass: "row-value",
                attrs: { disabled: "" },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "base-info" }, [
          _c("div", { staticClass: "info-title" }, [_vm._v("其他信息")]),
          _c(
            "div",
            { staticClass: "row margin-top padding-bottom" },
            [
              _c("div", { staticClass: "row-title" }, [_vm._v("经销商审核")]),
              _c("el-switch", {
                attrs: {
                  activeText: "通过",
                  inactiveText: "不通过",
                  disabled: _vm.checkState
                },
                on: {
                  change: function($event) {
                    _vm.changeState(_vm.state)
                  }
                },
                model: {
                  value: _vm.state,
                  callback: function($$v) {
                    _vm.state = $$v
                  },
                  expression: "state"
                }
              })
            ],
            1
          )
        ]),
        !_vm.checkState
          ? _c(
              "div",
              { staticClass: "submit" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "submit-button",
                    attrs: { type: "primary", loading: _vm.loading },
                    on: { click: _vm.submit }
                  },
                  [_vm._v("提交")]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }